/**
 * calendar calendar table styles
 */
.calendar-calendar {

  tr.odd, tr.even {
    background-color: #fff;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 auto;
    padding: 0;
    width: 100%; /* Setting at 100% causes problem in Internet Explorer. */
  }
  .month-view table {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .year-view td {
    width: 32%;
    padding: 1px;
    border: none;

    table td {
      width: 13%;
      padding: 0;
    }
  }
  tr {
    padding: 0;
    margin: 0;
  }
  th {
    text-align: center;
    margin: 0;

    a {
      font-weight: bold;
    }
  }
  td {
    width: 14%;
    border: 1px solid transparent;
    color: #424242;
    text-align: right;
    vertical-align: top;
    margin: 0;
    padding: 0;
  }
  .mini {
    font-size: 1.05em;
    text-align: center;
    padding: 6px 11px;
  }
}

/**
 * format for the week number in the first cell
 */
.calendar-calendar {
 td.week {
    width: 1%;
    min-width: 1%;
   }
  .week {
    clear: both;
    font-style: normal;
    color: #555;
    font-size: 0.8em;
  }
}

/**
 * IE6 does not support min-height, using Min-Height fast hack from http://www.dustindiaz.com/min-height-fast-hack
 */
.calendar-calendar {
  .inner {
    height: auto !important;
    height: 5em;
    padding: 0;
    margin: 0;

    div {
      padding: 0;
      margin: 0;
    }
  }
}


/**
 * Make sure paragraphs buried in calendar cells use padding, not margins, for separation so the background color doesn't come through.
 */
.calendar-calendar .inner p {
  padding: 0 0 .8em 0;
  margin: 0;
}
.calendar-calendar td a {
  text-decoration: none;
  color: #fff;
}
.calendar-calendar td a:hover {
  text-decoration: underline;
}
.calendar-calendar td.year,
.calendar-calendar td.month {
  text-align: center;
}

/**
 * format days of the week header cells
 */
.calendar-calendar th.days {
  color: #8cc63e;
  background-color: transparent;
  text-align: center;
  padding: 1px;
  margin: 0;
 }

/**
 * floating day number div
 */
.calendar-calendar div.day {
  float: right;
  text-align: center;
  padding: 0.125em 0.25em 0 0.25em;
  margin: 0;
  background-color: #f3f3f3;
  border: 1px solid gray;
  border-width: 0 0 1px 1px;
  clear: both;
  width: 2em;
}

/**
 * individual node container
 */
.calendar-calendar div.calendar {
  border: solid 1px #ddd;
  text-align: left;
  margin: 0 .25em .25em 0;
  width: 96%;
  float: right; /* needed to keep IE from hiding the floating day number */
  clear: both;
}
/**
 * No floating of day view content because there is no floating day number
 */
.calendar-calendar .day-view div.calendar {
  float: none;
  width: 98%;
  margin: 1% 1% 0 1%;
}

.calendar-calendar div.title {
  font-size:.8em;
  text-align: center;
}

.calendar-calendar div.title a {
  color: #000;
}
.calendar-calendar div.title a:hover {
  color: #c00;
}
.calendar-calendar .content {
  clear: both;
  padding: 3px;
  padding-left: 5px;
}
.calendar div.form-item {
  white-space: normal;
}

table td.mini,
table th.mini,
table.mini td.week {
  padding: 0 1px 0 0;
  margin: 0;
  }
.calendar-calendar .mini-day-off {
  padding: 0px;
}
.calendar-calendar .mini-day-on {
  padding: 0px;
}
.calendar-calendar .mini .title {
  font-size: .8em;
}
.mini .calendar-calendar .week {
  font-size: .7em;
}
.mini-row {
  width: 100%;
  border: none;
}
.mini{
  width: 32%;
  vertical-align: top;
}

/**
 * formatting for the legend stripe and block
 */
.calendar-calendar .stripe {
  height: 5px;
  width: auto;
  font-size:1px !important;
  line-height:1px !important;
}
.calendar-calendar .day-view .stripe {
  width: 100%;
}
table.calendar-legend {
  background-color: #ccc;
  width: 100%;
  margin: 0;
  padding: 0;
}
table.calendar-legend tr.odd .stripe,
table.calendar-legend tr.even .stripe {
  height: 12px !important;
  font-size: 9px !important;
  line-height: 10px !important;
}
.calendar-legend td {
  text-align: left;
  padding-left: 5px;
}

.calendar-empty {
  font-size: 1px;
  line-height: 1px;
}

/**
 * formatting for the full day view
 */
.calendar-calendar td.calendar-agenda-hour {
  text-align: right;
  border: none;
  border-top: 1px solid #CCCCCC;
  padding-top: .25em;
  width: 1%;
}
.calendar-calendar td.calendar-agenda-no-hours {
  min-width: 1%;
}
.calendar-calendar td.calendar-agenda-hour .calendar-hour {
  font-size: 1.2em;
  font-weight: bold;
}
.calendar-calendar td.calendar-agenda-hour .calendar-ampm {
  font-size: 1em;
}
.calendar-calendar td.calendar-agenda-items {
  border: 1px solid #CCCCCC;
  text-align: left;
}
.calendar-calendar td.calendar-agenda-items div.calendar {
  width: auto;
  padding: .25em;
  margin: 0;
}
.calendar-calendar div.calendar div.inner .calendar-agenda-empty {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em 0;
  background-color: #fff;
}

/**
 * the popup date selector for jumping to a new date
 */
.calendar-date-select form {
  text-align: right;
  float: right;
  width: 25%;
}
.calendar-date-select div,
.calendar-date-select input,
.calendar-date-select label {
  text-align: right;
  padding: 0;
  margin: 0;
  float: right;
  clear: both;
}
.calendar-date-select .description {
  float: right;
}
.calendar-label {
  font-weight: bold;
  display: block;
  clear: both;
}

.calendar-calendar td {
  background: #e8f4da;
}

.calendar-calendar div.date-nav {
  background-color: #ccc;
  color: #777;
  padding: 0.2em;
  width: auto;
  border: 1px solid #ccc;
}
.calendar-calendar div.date-nav a,
.calendar-calendar div.date-nav h3 {
  color: #777;
  text-decoration: none;
}
.calendar-calendar th.days {
  font-weight: normal;
}
.calendar-calendar td.empty {
  background: #ccc;
  border-color: #ccc;
  color: #aaa;
}
.calendar-calendar table.mini td.empty {
  background-color: #f5f9eb;
  border-color: transparent;
}
.calendar-calendar td div.day {
  border: 1px solid #ccc;
  border-top: none;
  border-right: none;
  margin-bottom: 2px;
}
.calendar-calendar td .inner div,
.calendar-calendar td .inner div a {
  background: #eee;
}

/**
 * Set small font size for all items in calendar div, themes can override this.
 * Use x-small instead of em to avoid multiplying effect in nested items.
 */
.calendar-calendar div.calendar {
  border: none;
  line-height: 1.4em;
}
.calendar-calendar td .inner div.calendar div {
  line-height: 1.3em;
    padding: 5px;
}
.calendar-calendar td .inner div.calendar div a {
  border: none;
  background: #ffc;
  color: #424242;
  padding: 4px 1px;
  font-weight: normal;
}
.calendar-calendar td .inner div.calendar div.calendar-more,
.calendar-calendar td .inner div.calendar div.calendar-more a {
  color: #444;
  background: #fff;
  text-align: right;
}
.calendar-calendar td .inner .view-field,
.calendar-calendar td .inner .view-field a {
  color: #444;
  font-weight: normal;
}

.calendar-calendar td span.date-display-single,
.calendar-calendar td span.date-display-start,
.calendar-calendar td span.date-display-end,
.calendar-calendar td span.date-display-separator {
  font-weight: bold;
}
.calendar-calendar td .inner div.day a {
  color: #4b85ac;
}
.calendar-calendar tr td.today,
.calendar-calendar tr.odd td.today,
.calendar-calendar tr.even td.today {
  background-color: #fff;

}
.calendar-calendar td .inner div.calendar div div.view-data-node-data-field-date-field-date-value {
}

/**
 * The following are not used by default but are available for themes
 */
.calendar-calendar td.past {}
.calendar-calendar td.future {}
.calendar-calendar td.has-events {
  background-color: #24ace0;
}
.calendar-calendar td.has-no-events {}

/**
 * Multi day styles
 */
.calendar-calendar tbody {
  border-top: none;
}

.calendar-calendar .month-view .full .inner,
.calendar-calendar .week-view .full .multi-day .inner {
  height: auto;
  min-height: auto;
}
.calendar-calendar .week-view .full .calendar-agenda-hour
.calendar-calendar .month-view .full .single-day .inner .view-item {
  float: left;
  width: 100%;
}

.calendar-calendar .week-view .full .calendar-agenda-hour,
.calendar-calendar .day-view .full .calendar-agenda-hour {
  padding-right: 2px;
}

.calendar-calendar .week-view .full .calendar-agenda-hour {
  width: 6%;
  min-width: 0px;
}

.calendar-calendar .week-view .full .days {
  width: 13%;
}

.calendar-calendar .month-view .full div.calendar,
.calendar-calendar .week-view .full div.calendar,
.calendar-calendar .day-view div.calendar {
  width: auto;
}

.calendar-calendar .month-view .full tr.date-box,
.calendar-calendar .month-view .full tr.date-box td,
.calendar-calendar .month-view .full tr.multi-day,
.calendar-calendar .month-view .full tr.multi-day td {
  height: 19px;
  max-height: 19px;
}

.calendar-calendar .month-view .full tr.single-day .no-entry,
.calendar-calendar .month-view .full tr.single-day .no-entry .inner {
  height: 44px !important;
  line-height: 44px;
}

.calendar-calendar .month-view .full tr.single-day .noentry-multi-day,
.calendar-calendar .month-view .full tr.single-day .noentry-multi-day .inner {
  height: 22px !important;
  line-height: 22px;
}

.calendar-calendar .month-view .full td,
.calendar-calendar .week-view .full td,
.calendar-calendar .day-view  td {
  vertical-align: top;
  padding: 1px 8px 4px;
}

.calendar-calendar .month-view .full td.date-box {
  height: 1%;
  border-bottom: 0px;
  padding-bottom: 2px;
}

.calendar-calendar .month-view .full .week {
  font-size: inherit;
}

.calendar-calendar .month-view .full .week a,
.calendar-calendar .week-view .full .week a {
  color: #4b85ac;
}

.calendar-calendar .month-view .full td .inner div.day,
.calendar-calendar .month-view .full td .inner div.day a {
  border: none;
  background: none;
  margin-bottom: 0px;
}

.calendar-calendar .month-view .full td.date-box .inner,
.calendar-calendar .week-view .full td.date-box .inner {
  min-height: inherit;
}

.calendar-calendar .month-view .full td.multi-day,
.calendar-calendar .week-view .full td.multi-day {
  border-top: 0px;
  border-bottom: 0px;
}

.calendar-calendar .week-view .full .first td.multi-day {
  border-top: 1px solid #CCCCCC;
}

.calendar-calendar .month-view .full td.single-day {
  border-top: 0px;
}

.calendar-calendar .month-view .full td.multi-day .inner,
.calendar-calendar .week-view .full td.multi-day .inner,
.calendar-calendar .day-view .full td.multi-day .inner {
  min-height: inherit;
  width: auto;
  position: relative;
}

.calendar-calendar .month-view .full td.multi-day.no-entry {
  min-height: 0px;
}

.calendar-calendar .month-view .full td.single-day .calendar-empty,
.calendar-calendar .month-view .full td.single-day.empty,
.calendar-calendar .month-view .full td.date-box.empty {
  background: #F4F4F4;
}

.calendar-calendar .month-view .full td.single-day .inner div,
.calendar-calendar .month-view .full td.single-day .inner div a ,
.calendar-calendar .month-view .full td.multi-day .inner div,
.calendar-calendar .month-view .full td.multi-day .inner div a ,
.calendar-calendar .month-view .full td .inner div.calendar.monthview div,
.calendar-calendar .month-view .full td .inner div.calendar.monthview div a,
.calendar-calendar .week-view .full td.single-day .inner div,
.calendar-calendar .week-view .full td.single-day .inner div a ,
.calendar-calendar .week-view .full td.multi-day .inner div,
.calendar-calendar .week-view .full td.multi-day .inner div a ,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div,
.calendar-calendar .week-view .full td .inner div.calendar.weekview div a,
.calendar-calendar .day-view .full td .inner div.view-item,
.calendar-calendar .day-view .full td .inner div.calendar div,
.calendar-calendar .day-view .full td .inner div.calendar div a {
  background: none;
}

.calendar-calendar .day-view .full td .inner div.calendar div,
.calendar-calendar .day-view .full td .inner div.calendar div a {
  margin: 0px 3px;
}

.calendar-calendar .day-view .full td .inner div.calendar div.stripe {
  margin: 0px;
}

.calendar-calendar .month-view .full tr td.today,
.calendar-calendar .month-view .full tr.odd td.today,
.calendar-calendar .month-view .full tr.even td.today {
  background: none;
  border-left: 2px solid #C0D72F;
  border-right: 2px solid #C0D72F;
}

.calendar-calendar .month-view .full td.date-box.today {
  border-width: 2px 2px 0px 2px;
  border-style: solid;
  border-color: #C0D72F;
}

.calendar-calendar .month-view .full tr td.single-day.today {
  border-bottom: 2px solid #C0D72F;
}

.calendar-calendar .month-view .full tr td.multi-day.starts-today {
  border-left: 2px solid #7C7F12;
}

.calendar-calendar .month-view .full tr td.multi-day.ends-today {
  border-right: 2px solid #7C7F12;
}

.calendar-calendar .month-view .full tr td.multi-day,
.calendar-calendar .month-view .full tr td.single-day {
  border-top: 0px;
}

.calendar-calendar .month-view .full tr td.multi-day,
.calendar-calendar .month-view .full tr td.date-box {
  border-bottom: 0px;
}

.calendar-calendar .month-view .full .inner .monthview,
.calendar-calendar .week-view .full .inner .weekview,
.calendar-calendar .day-view .full .inner .dayview {
  width: auto;
  float: none;
  display: block;
  margin: .25em auto;
  position: relative;
}

.calendar-calendar .month-view .full td.single-day div.monthview,
.calendar-calendar .week-view .full td.single-day div.weekview,
.calendar-calendar .day-view .full td.single-day div.dayview {
  background: #F3F3F3;
  width: auto;
  overflow: hidden;
}

.calendar-calendar .month-view .full td.single-day .calendar-more div.monthview {
  background: none;
}

.calendar-calendar .day-view td div.dayview {
  padding: 0px;
}

.calendar-calendar .month-view .full td.multi-day div.monthview,
.calendar-calendar .week-view .full td.multi-day div.weekview,
.calendar-calendar .day-view .full td.multi-day div.dayview {
  background: #74a5d7;
  height: 1.9em;
  overflow: hidden;
  margin: 0px auto;
  color: #ffffff;
  position: relative;
}

.calendar-calendar .week-view .full td.multi-day div.weekview {
  height: 3.5em;
}

.calendar-calendar .month-view .full td.multi-day .inner .view-field,
.calendar-calendar .month-view .full td.multi-day .inner .view-field a,
.calendar-calendar .week-view .full td.multi-day .inner .view-field,
.calendar-calendar .week-view .full td.multi-day .inner .view-field a,
.calendar-calendar .day-view .full td.multi-day .inner .view-field,
.calendar-calendar .day-view .full td.multi-day .inner .view-field a {
  color: #ffffff;
}

.calendar-calendar .full td.multi-day .calendar .view-field,
.calendar-calendar .full td.single-day .calendar .view-field {
}

.calendar-calendar .day-view .full td.multi-day div.dayview,
.calendar-calendar .week-view .full td.multi-day div.weekview {
  margin-bottom: 2px;
}

.calendar-calendar .month-view .full td.multi-day .calendar.monthview .view-field {
  white-space: nowrap;
  float: left;
  margin-right: 3px;
}

.calendar-calendar .week-view .full td.multi-day .calendar.weekview .view-field {
  white-space: nowrap;
  display: inline;
  margin-right: 3px;
}

.calendar-calendar .day-view .full td.multi-day .calendar.weekview .view-field {
  display: block;
}

.calendar-calendar .month-view .full td.multi-day .calendar.monthview .contents,
.calendar-calendar .week-view .full td.multi-day .calendar.weekview .contents {
  position: absolute;
  width: 3000px;
  left: 5px;
}

.calendar-calendar .day-view td .stripe,
.calendar-calendar .month-view .full td .stripe,
.calendar-calendar .week-view .full td .stripe {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 6px;
  z-index: 2;
}

.calendar-calendar .full td.single-day .continuation,
.calendar-calendar .full td.single-day .continues,
.calendar-calendar .full td.single-day .cutoff {
  display: none;
}

.calendar-calendar .month-view .full td.multi-day  .inner .monthview .continuation,
.calendar-calendar .week-view .full td.multi-day  .inner .weekview .continuation {
  float:left;
  margin-right: 3px;
  height: 1.9em;
}

.calendar-calendar .week-view .full td.multi-day  .inner .weekview .continuation {
  height: 2.75em;
  padding-top: 0.75em;
  margin-right: 8px;
}

.calendar-calendar .month-view .full td.multi-day .inner .monthview .continues,
.calendar-calendar .month-view .full td.multi-day .inner .monthview .cutoff,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .continues,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff {
  position: absolute;
  right: 0px !important;
  right: -1px;
  width: 10px;
  text-align: left;
  background: #74a5d7;
  -moz-border-radius: 0px 5px 5px 0px;
  border-radius: 0px 5px 5px 0px;
  height: 1.9em;
  padding-left: 6px;
  z-index: 1;
}

.calendar-calendar .week-view .full td.multi-day .inner .weekview .continues,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff {
  height: 2.75em;
  padding-top: 0.75em;
}

.calendar-calendar .month-view .full td.multi-day .inner .monthview .cutoff,
.calendar-calendar .week-view .full td.multi-day .inner .weekview .cutoff {
  width: 8px;
  padding-left: 0px;
}

.calendar-calendar .week-view .full td.multi-day {
  padding: 2px;
}

.calendar-calendar .week-view td.single-day div.calendar {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.calendar-calendar .week-view .full tr.last td.multi-day {
  border-bottom:1px solid #CCCCCC;
}

/**
 * Restyle Header
 */
.view .date-nav-wrapper .clear-block {
  margin-bottom: 10px;
}

.view .date-nav-wrapper  {
  position: relative;
  margin-top: 5px;
  width: 100%;

    .ajax-progress-throbber {
      display: none;
     }
  .date-heading {
    position: relative;
    width: 100%;
    top: 0px;
    text-align: center;
    z-index: 0;

    h3 {
      font-size: 1.4em;
      line-height: 1.8em;
      font-weight: normal;
    }
  }
  .date-nav {
    background-color: transparent;
    border: 0px;
    height: 30px;
    height: auto;
    min-height: 30px;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0;

    a:hover {
      text-decoration: underline;
    }
  }
  .date-prev {
    list-style: none;
    background: none repeat scroll 0 0 #dfdfdf;
    float: none;
    padding: 5px 0;
    position: absolute;
    right: 28px;
    left: auto;
    text-align: right;
    top: 0px;
    width: auto;
    z-index: 1;
    font-size: 12px;

    a {
      margin-left: 0;
      font-weight: bold;
    }
  }
  .date-next {
    list-style: none;
    background: none repeat scroll 0 0 #dfdfdf;
    float: none;
    padding: 5px 0;
    position: absolute;
    right: 0px;
    text-align: right;
    top: 0px;
    width: auto;
    z-index: 1;
    font-size: 12px;

    a {
      margin-right: 0;
      font-weight: bold;
    }
  }
  .date-prev a,
  .date-next a {
    text-decoration: none;
    color: inherit;
    font-size: 12px;
    padding: 0 8px;

    &:hover {
      background-color: #d7d7d7;
    }
  }
}

.block-views .view .date-nav-wrapper .date-prev {
  left: 0;
  right: auto;
}

.attachment .calendar-calendar {
  margin-top: 20px;
  clear: both;
}

.calendar-calendar th a,
.attachment .calendar-calendar th {
  background-color: transparent;
  border: 0px;
}

.attachment .calendar-calendar th.calendar-agenda-hour {
  color: #777777;
  font-weight: bold;
  text-align: right;
}

.view-calendar .feed-icon {
  margin-top: 5px;
}

.view table.mini .date-prev,
.view table.mini .date-next {
  background: none;
}

.date-nav div.date-prev,
.date-nav div.date-next,
.date-nav {
  width:auto;
}

ul.calendar-links,
.region-content ul.calendar-links {
  margin:0;
  padding:0;
}

.year-view div.month-view div.date-nav {
  background-color: #DFDFDF;
  min-height: 20px;
}

#key {
  float: right;
  font-size: 0.84615em;
  line-height: 1.3em;
  padding-bottom: 1.4em;
}

.view-calendar .color {
    padding: 0 10px 0 5px;
}

.view-calendar span.mhc{
  border-right: 20px solid #16c1f3;
}

.view-calendar.view-display-id-page_3 {
  .date-nav-wrapper,
  .views-row {

  }
}
